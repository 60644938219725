<template>
  <section class="w-full my-3">
    <div class="intro-y flex flex-col md:flex-row items-center">
      <h1 class="text-lg font-bold truncate mr-5">Información de la Obra - Centro Salud</h1>
      <div class="ml-auto flex flex-col md:flex-row items-center text-theme-1 dark:text-theme-10 w-full md:w-auto">
        <Button icon="pi pi-info-circle" class="p-button-rounded p-button-info p-button-text" v-tooltip.left="'Informacion del presupuesto de la obra por fases'"/>
      </div>
    </div>
    <div class="box w-full mb-4 b-t-c">
      <div class="p-2">
        <div class="flex justify-between items-center">
          <span class="text-sm font-bold capitalize p-2">
            Totales
          </span>
        </div>
        <div class="flex flex-col items-center dark:bg-gray-900">
          <div class="grid bg-white dark:bg-dark-1 dark:text-gray-500 w-full sm:grid-cols-2 lg:grid-cols-6 xl:grid-cols-6 xxl:grid-cols-6">
            <div class="flex flex-col items-center bg-light-primary rounded border-primary border border-dashed p-2 m-2">
              <div class="text-xs text-gray-500 font-medium">Total Presupuesto Inicial</div>
              <div class="flex items-center pt-1">
                <div class="text-base font-bold text-gray-700">{{ $h.formatCurrency(totalValuePresupuesto, 2) }}</div>
              </div>
            </div>
            <div class="flex flex-col items-center bg-light-info rounded border-info border border-dashed p-2 m-2">
              <div class="text-xs text-gray-500 font-medium">Total Adición Presupuesto</div>
              <div class="flex items-center pt-1">
                <div class="text-base font-bold text-gray-700">{{ $h.formatCurrency(totalValueDiferencia, 2) }}</div>
              </div>
            </div>
            <div class="flex flex-col items-center bg-light-secondary rounded border-secondary border border-dashed p-2 m-2">
              <div class="text-xs text-gray-500 font-medium">Total Adición Presupuesto Agosto 2023</div>
              <div class="flex items-center pt-1">
                <div class="text-base font-bold text-gray-700">{{ $h.formatCurrency(totalAdicionAgo, 2) }}</div>
              </div>
            </div>
            <div class="flex flex-col items-center bg-light-success rounded border-success border border-dashed p-2 m-2">
              <div class="text-xs text-gray-500 font-medium">Total Presupuesto Final</div>
              <div class="flex items-center pt-1">
                <div class="text-base font-bold text-gray-700">{{ $h.formatCurrency(totalAdicionPresupuesto, 2) }}</div>
              </div>
            </div>
            <div class="flex flex-col items-center bg-light-warning rounded border-warning border border-dashed p-2 m-2">
              <div class="text-xs text-gray-500 font-medium">Total Ejecución de Obra</div>
              <div class="flex items-center pt-1">
                <div class="text-base font-bold text-gray-700">{{ $h.formatCurrency(totalValueEjecucion, 2) }}</div>
              </div>
            </div>
            <div class="flex flex-col items-center bg-light-danger rounded border-danger border border-dashed p-2 m-2">
              <div class="text-xs text-gray-500 font-medium">Total Saldo</div>
              <div class="flex items-center pt-1">
                <div class="text-base font-bold text-gray-700">{{ $h.formatCurrency(totalValueSaldo, 2) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Componente de la fase -->
  <div v-for="(stage, index) in infoStages" :key="index">
    <stage-item
      :numberStage="stage.stage"
      :descriptionStage="stage.description"
      :subtotalStage="stage.subtotal"
      :executionValueStage="stage.value"
      :vlrContratado="stage.vlrContratado"
      :difVlrContratadoVlrPresupuesto="stage.diferenciaVlrContratadoVlrPresupuesto"
      :vlrAdicionAgo="stage.vlrAdicionAgo"
      :isVisible="stage.isVisible"
      @stageDetails="stageDetails"
    />
  </div>

</template>

<script>
import { defineAsyncComponent, onMounted, ref } from 'vue'
import listStages from '../../../services/listStages'
import lodash from 'lodash'

export default {
  name: 'DashboardIndex',
  components: {
    stageItem: defineAsyncComponent(() => import('./components/stage'))
  },
  setup () {
    const infoStages = ref({})
    const viewData = ref(false)
    const isVisible = ref(false)
    const totalValuePresupuesto = ref(0)
    const totalValueDiferencia = ref(0)
    const totalAdicionAgo = ref(0)
    const totalAdicionPresupuesto = ref(0)
    const totalValueEjecucion = ref(0)
    const totalValueSaldo = ref(0)

    /* Obtener el listado de las fases */
    const fetchStages = () => {
      return listStages({}).then(({ status, data }) => {
        infoStages.value = data.map(v => ({ ...v, isVisible: isVisible.value }))

        totalValuePresupuesto.value = lodash.sumBy(infoStages.value, 'subtotal')
        totalValueDiferencia.value = lodash.sumBy(infoStages.value, 'diferenciaVlrContratadoVlrPresupuesto')
        totalAdicionAgo.value = lodash.sumBy(infoStages.value, 'vlrAdicionAgo')
        totalAdicionPresupuesto.value = totalValuePresupuesto.value + totalValueDiferencia.value + totalAdicionAgo.value
        totalValueEjecucion.value = lodash.sumBy(infoStages.value, 'value')
        totalValueSaldo.value = totalAdicionPresupuesto.value - totalValueEjecucion.value

        if (infoStages.value.length > 0) {
          viewData.value = true
        } else {
          viewData.value = true
        }
      }).catch(err => {
        console.error(err.message)
      })
    }

    /* Detalle */
    const stageDetails = (numberStage, isVisible) => {
      const arrayTest = infoStages.value.filter((item) => {
        return item.stage === numberStage
      })

      arrayTest[0].isVisible = !isVisible
    }

    onMounted(() => {
      fetchStages()
    })

    return {
      infoStages,
      isVisible,
      viewData,
      stageDetails,
      totalValuePresupuesto,
      totalValueDiferencia,
      totalValueEjecucion,
      totalAdicionAgo,
      totalAdicionPresupuesto,
      totalValueSaldo
    }
  }
}
</script>

<style scoped>

</style>
